
import {setPageHeader} from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import AddManufacturer from "@/modules/catalog/manufacturers/components/AddManufacturer.vue"
import EditManufacturer from "@/modules/catalog/manufacturers/components/EditManufacturer.vue"
import ManufacturersList from "@/modules/catalog/manufacturers/components/ManufacturersList.vue"

export default defineComponent({
	name: "manufacturers",
	components: {
		AddManufacturer,
		EditManufacturer,
		ManufacturersList,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
			    title: "message.MANUFACTURERS",
			    actionButton: {
                    ability: 'add_manufacturer',
                    pageAction: {
                        action: "addManufacturerAction",
                    },
                    button: {
                        icon: "bi bi-plus",
                        openModal: true,
                    }
			    },
			    breadCrumbs:[
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.CATALOG", link: "#"},
                    { name: "message.MANUFACTURERS"}
			    ]
			})
		});
	},
});
