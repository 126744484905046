import { gql } from "graphql-tag";
import { MANUFACTURER_FIELDS } from "./Fragments";

export const CREATE_MANUFACTURER = gql`
	mutation CreateManufacturer($input: ManufacturerInput!) {
		create_manufacturer(manufacturer: $input) {
			...ManufacturerFields
		}
	}
	${MANUFACTURER_FIELDS}
`;

export const UPDATE_MANUFACTURER = gql`
	mutation UpdateManufacturer($input: ManufacturerInput!) {
		update_manufacturer(manufacturer: $input) {
			...ManufacturerFields
		}
	}
	${MANUFACTURER_FIELDS}
`;

export const DELETE_MANUFACTURER = gql`
	mutation DeleteManufacturer($id: Int!) {
		delete_manufacturer(id: $id) {
			...ManufacturerFields
		}
	}
	${MANUFACTURER_FIELDS}
`;

export default { CREATE_MANUFACTURER, UPDATE_MANUFACTURER, DELETE_MANUFACTURER };
